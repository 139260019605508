import React from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown/with-html"

import { Button } from "../buttons/buttons"
import { replaceUnicode } from "../utils/utils"

// Standar heading
export const HeadingBasicSm = ({
  caption,
  h1,
  h2,
  h3,
  h4,
  text,
  btnText,
  btnLink,
  btnClassName,
  children,
  className,
  isDetails,
}) => {

  const toggleDetailPara = () => {
    let panel = document.getElementById("details-para")
    let iconBtn = document.getElementById("icon-details-btn")
    let overlayText = document.getElementById("overlay-text")
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null
      overlayText.style.backgroundImage = "linear-gradient(0deg,#fff,hsla(0,0%,100%,.5))"
      iconBtn.classList.remove("fa-chevron-up")
      iconBtn.classList.add("fa-chevron-down")
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
      overlayText.style.backgroundImage = "none"
      iconBtn.classList.remove("fa-chevron-down")
      iconBtn.classList.add("fa-chevron-up")
    } 
  }

  // const trimString = (paragraph) => {
  //   const maxChar = 206
  //   let trimAct = paragraph.substr(0, maxChar)
  //   trimAct = trimAct.substr(0, Math.min(trimAct.length, trimAct.lastIndexOf(" ")))
  // }

  return (
    <>
      <div className={`heading heading-basic ${className}`}>
        {caption && <small className="caption">{caption}</small>}
        {h1 && <h1 className="h1">{replaceUnicode(h1)}</h1>}
        {h2 && <h2 className="h2">{replaceUnicode(h2)}</h2>}
        {h3 && <h3 className="h3">{replaceUnicode(h3)}</h3>}
        {h4 && <h4 className="h4">{replaceUnicode(h4)}</h4>}
        <div className="details-para" id="details-para">
        {text &&
          text.map((item, i) => (
            <ReactMarkdown key={item} source={item} escapeHtml={false} />
          ))}
        </div>
        <div className="overlay-text" id="overlay-text"></div>
        <p className="details-plc-btn" id="details-plc-btn">
          <button 
            className="btn isdetail-btn" 
            id="is-detail-btn" 
            onClick={() => toggleDetailPara()}
          >
            Selengkapnya&nbsp;&nbsp;&nbsp;&nbsp;
            <i className="fa fa-chevron-down" id="icon-details-btn"></i>
          </button>
        </p>
        {btnText && (
          <Button variant="link" link={btnLink} className={btnClassName}>
            {btnText}
          </Button>
        )}
        {children}
      </div>
    </>
  )
}

HeadingBasicSm.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

HeadingBasicSm.defaultProps = {
  className: "",
}

export const HeadingBasic = ({
  caption,
  h1,
  h2,
  h3,
  h4,
  text,
  btnText,
  btnLink,
  btnClassName,
  children,
  className,
}) => {
  return (
    <>
      <div className={`heading heading-basic ${className}`}>
        {caption && <small className="caption">{caption}</small>}
        {h1 && <h1 className="h1">{replaceUnicode(h1)}</h1>}
        {h2 && <h2 className="h2">{replaceUnicode(h2)}</h2>}
        {h3 && <h3 className="h3">{replaceUnicode(h3)}</h3>}
        {h4 && <h4 className="h4">{replaceUnicode(h4)}</h4>}
        {text &&
          text.map((item, i) => (
            <ReactMarkdown key={item} source={item} escapeHtml={false} />
          ))}
        {btnText && (
          <Button variant="link" link={btnLink} className={btnClassName}>
            {btnText}
          </Button>
        )}
        {children}
      </div>
    </>
  )
}

HeadingBasic.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

HeadingBasic.defaultProps = {
  className: "",
}

// Alternate heading with columns
export const HeadingColumn = ({
  caption,
  h1,
  h2,
  h3,
  text,
  btnText,
  btnLink,
  btnClassName,
  children,
  className,
}) => {
  return (
    <>
      <div className={`heading heading-column ${className}`}>
        <div className="row">
          <div className="col-md-3">
            {h1 && <h1 dangerouslySetInnerHTML={{__html: h1,}}></h1>}
            {h2 && <h2 dangerouslySetInnerHTML={{__html: h2,}}></h2>}
            {h3 && <h3 dangerouslySetInnerHTML={{__html: h3,}}></h3>}
            {caption && <small dangerouslySetInnerHTML={{__html: caption,}}></small>}
          </div>
          <div className="col-md-9">
            {text &&
              text.map((text, i) => {
                return <p key={`text-${i}`}><ReactMarkdown key={i} source={text} escapeHtml={false} /></p>
              })}
            {btnText && (
              <Button variant="link" link={btnLink} className={btnClassName}>
                {btnText}
              </Button>
            )}
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

HeadingColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

HeadingColumn.defaultProps = {
  className: "",
}
