import React from "react"

import { Card } from "../card/card"
import { Section, Container } from "../grid/grid"

export const AcfListImage = ({ data }) => {
  const listImage = data ? data : false
  return (
    <Section
      className={`${(listImage.acf && listImage.acf.bgColor) || ""} ${
        listImage.attributes && listImage.attributes.className
          ? listImage.attributes.className
          : ""
      }`}
    >
      <Container className="mw-lg">
        <h2 className="h2">{listImage.acf && listImage.acf.title}</h2>
        <p className="mb-5">{listImage.acf && listImage.acf.text}</p>
        <div className="row">
          {listImage.acf &&
            listImage.acf.list.map((item, i) => {
              return (
                <div className="col-md-4 col-6 mb-3">
                  <Card
                    className="list-image"
                    variant="boxless"
                    title={item.title}
                    titleClassName="h6"
                    text={item.text}
                    textClassName="text"
                    img={item.img.sourceUrl}
                    imgHeight="h-ratio-1-1"
                    key={`${item.title}${i}`}
                  />
                </div>
              )
            })}
        </div>
      </Container>
    </Section>
  )
}
